import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FNavigationMenu%2FnavigationMenu.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41V246bMBB9z1f4pVKQ4siQ267z0t%2FoU2XAAXeNbRmTS6v8e2WbBBvI7koIwTCemXNmzrD%2Bvc9urfqDwL8FAEq2zDApMNCUE8PO9Li4L9a9T%2Bp9SFkyUWGQZup6HAxQs6o2GKDoCK7lmWp3sOh0KzUGSjJhqLZHT4xzDM5ELyGUXN4uiiXWXkhuPccfhriZi8iZoLCmPm%2FqAkph4KW37BB62lpz4xQDIXVD%2BGBlfykG6c4D6bMyUVPNjLUYTcSDEfcVoHXWAkpaCmXnXHKpS6qhJiXrWgy2NtRQ58bVmZPio9KyEyWcQSYiZFt3oiG6YgJyerI4fHlhMZa4lbecpG7GZQ3hdi7c0xEDLQ0xdJm%2BoZJWUeb9aARI3kreGWpzl6xVnNwwqDQrreHMWpYzzswNg5qVJRXWar9CQxvFiaEWa9eI1g6TosQssxVIT9o1%2BMJKU7tZAeDRQPciFSlcUDRD7ls0cEPjjFS22%2F7lc7JPiY97hW1NSnnBAKkr2Njb3t50lZMlWoH%2BWqe7ZOVcMntL0axPloz708N43ZeDI%2FuJNv2SZPfMaUAe6YwM%2BXu8f7MJQy1vfkhnBnmi9uBQoOyv53tG%2FUOodxckFOTeJ4%2FUfPBqvtTMUNgqUjg5XzRRx%2FEqQOv34yCiXBojm1kZzWp6ijEu9MvlRCZ4ejJf1B7B3HqYM0nOyQRoeoibkrvMzzk6cXoNBibbxd7FsBuCtTJdcdMtTZPPaPJx53f7ffGzoSUjYNmQK%2BwLO%2BwP6pq4U%2FG%2FZvi5%2BE3oqLkHblnvNkdh6Lfv%2FZ7UCCmclub%2FedYe7ojBezrqjj9FNBXmcTASEvJWt6P65weo0BasaPdoxftrCVOEfiSBR1%2Fqy80fwz6MYedcFh%2BfJUTJOMb7Nykmryi%2Bzzc9Rdlm3PWonbF27ov7f5ZSf02sCAAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var NavigationMenuWrapperStyle = '_62yspj0';
export var arrowIconRecipe = _7a468({defaultClassName:'_62yspj4',variantClassNames:{open:{true:'_62yspj5'}},defaultVariants:{},compoundVariants:[]});
export var iconStyle = '_62yspjc';
export var iconWrapperStyle = '_62yspjb';
export var menuItemStyle = '_62yspj8';
export var menuWrapperStyle = _7a468({defaultClassName:'_62yspj6',variantClassNames:{open:{true:'_62yspj7'}},defaultVariants:{},compoundVariants:[]});
export var navMenuLinkWrapperStyle = '_62yspj1';
export var navigationMenuLinkStyle = _7a468({defaultClassName:'_62yspj2',variantClassNames:{active:{true:'_62yspj3'}},defaultVariants:{},compoundVariants:[]});
export var subtitleStyle = '_62yspja';
export var titleStyle = '_62yspj9';