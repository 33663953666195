import { StyleRule } from "@vanilla-extract/css";
import { breakpoints } from "@/uikit/variables";

const vw = (px, breakpoint) => `${(px / breakpoint) * 100}vw`;

export const vwLg = (px) => vw(px, breakpoints.lg);
export const vwMd = (px) => vw(px, breakpoints.md);
export const vwSm = (px) => vw(px, breakpoints.sm);

type MediaConfig = Record<keyof typeof breakpoints, StyleRule>;

const media = (breakpoint) => {
  return (args: StyleRule): { [query: string]: StyleRule } => {
    return {
      [`(max-width: ${breakpoint - 1}px)`]: args,
    };
  };
};
export function mediaMap(config: Partial<MediaConfig>) {
  let result = {};
  for (const [key, value] of Object.entries(config)) {
    result = {
      ...result,
      [`(max-width: ${breakpoints[key] - 1}px)`]: value,
    };
  }

  return result;
}

export const mediaLg = media(breakpoints.lg);
export const mediaMd = media(breakpoints.md);
export const mediaSm = media(breakpoints.sm);
export const mediaXs = media(breakpoints.xs);
export const mediaDemo = media(breakpoints.demo);
export const mediaHeaderMobile = media(breakpoints.mobileHeader);

export const transition = (prop) => {
  const params = "0.2s ease-out";
  return prop.map ? `${prop.map((p) => `${p} ${params}`).join(",")}` : `${prop} ${params}`;
};

export function clsx(...args: Array<string | undefined>) {
  return args.filter(Boolean).join(" ");
}

export const isInPreviewMode = () =>
  typeof window !== "undefined" &&
  (window !== parent ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ||
    location.search.includes("__vercel_draft=1"));
