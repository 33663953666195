import { Header } from "@/components/Header";
import MobileMenu from "@/components/MobileMenu";
import { WebsiteMeta } from "@/components/meta/WebsiteMeta";
import { AnalyticsProvider } from "@/core/analytics";
import { resolveRef } from "@/core/sanityAPI/client-resolvers";
import { PageData } from "@/core/sanityAPI/fetchers";
import { themeClass } from "@/styles/theme.css";
import { clsx, isInPreviewMode } from "@/uikit/utils";
import dynamic from "next/dynamic";
import { Inter, Ubuntu_Mono, Source_Serif_4 } from "next/font/google";
import React, { useEffect, useState } from "react";
import "src/styles/global.css";
import { AppProps } from "next/app";
import { CookiesNoticeSchema, PageMetaSchema } from "@/schema";
import { useCookiesConcentState } from "@/components/CookiesNotice/storage";
import { PageLikeObject } from "@/core/sanityAPI/fetchers/base";
import { isBlogArticle } from "@/core/utils";
import { TypographyContext } from "@/uikit/Typography";

const Footer = dynamic(() => import("@/components/Footer").then((mod) => mod.Footer), { ssr: true });
const CookiesNotice = dynamic(() => import("@/components/CookiesNotice"), { ssr: false });
const VisualEditing = dynamic(() => import("@/components/VisualEditing"), { ssr: false });

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
  preload: true,
  adjustFontFallback: true,
  variable: "--family-sans",
  fallback: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "system-ui", "sans-serif"],
});

const ubuntuMono = Ubuntu_Mono({
  subsets: ["latin"],
  display: "swap",
  preload: true,
  adjustFontFallback: true,
  variable: "--family-mono",
  weight: ["400", "700"],
  fallback: ["Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", "monospace"],
});

const sourceSerif = Source_Serif_4({
  subsets: ["latin"],
  display: "swap",
  preload: true,
  adjustFontFallback: true,
  variable: "--family-serif",
  fallback: ["Source Serif Pro", "Georgia", "Times New Roman", "Times", "serif"],
});

function getFinalMeta(page?: PageLikeObject): PageMetaSchema | undefined {
  if (!page) {
    return undefined;
  }

  let title = page.meta?.title;
  let pageCard = page.meta?.seo?.cardImage;

  if (isBlogArticle(page)) {
    pageCard = page.meta?.seo?.cardImage || page.heroImage;
    title = title || page.title;
  }

  const meta = page.meta as PageMetaSchema;

  return {
    _type: meta?._type,
    title: title || "",
    seo: {
      _type: meta?.seo?._type,
      description: meta?.seo?.description,
      cardImage: pageCard,
    },
  };
}

export default function App({ Component, pageProps }: AppProps<PageData>) {
  const { existingConsent, setExistingConsent } = useCookiesConcentState();

  const [cookiesBannerData, setCookiesBannerData] = React.useState<CookiesNoticeSchema | undefined>(undefined);

  const header = resolveRef(pageProps.site?.header);
  const footer = resolveRef(pageProps.site?.footer);
  const cookiesConsentBanner = resolveRef(pageProps.site?.cookiesConsentBanner);

  useEffect(() => {
    setTimeout(() => {
      setCookiesBannerData(cookiesConsentBanner);
    }, 1000);
  });

  const shouldShowBanner = !existingConsent && cookiesBannerData;
  const meta = getFinalMeta(pageProps.body);
  const wrappedClass = clsx(inter.className, inter.variable, sourceSerif.variable, ubuntuMono.variable, themeClass);
  const [stegaEnabled, setStegaEnabled] = useState(isInPreviewMode());

  useEffect(() => {
    setStegaEnabled(isInPreviewMode());
  }, []);

  const h1Tag = useState(false);

  return (
    <TypographyContext.Provider value={{ h1Tag }}>
      <div id="wrapper" className={wrappedClass}>
        {header && <Header data={header} />}

        <Component {...pageProps} />

        {meta && <WebsiteMeta meta={meta} slug={pageProps.slug} />}

        {footer && <Footer {...footer} />}

        {shouldShowBanner && <CookiesNotice data={cookiesBannerData} onPermissionChange={setExistingConsent} />}

        {header && <MobileMenu {...header} />}

        <AnalyticsProvider permission={existingConsent} />

        {stegaEnabled && <VisualEditing />}
      </div>
    </TypographyContext.Provider>
  );
}
