import { SiteLinkSchema, TextLinkSchema } from "@/schema";
import React, { ReactNode } from "react";

import { Typography } from "@/uikit/Typography";
import { Link } from "@/uikit/Link";
import { Svg } from "@/uikit/Svg";

import { iconStyle, iconRecipe, buttonStyle, typographyStyle } from "./button.css";
import { isSchemaObject } from "@/core/utils";
import { clsx } from "../utils";

export interface ButtonProps {
  children?: string;
  className?: string;
  to?: SiteLinkSchema | TextLinkSchema;
  iconUrl?: string;
  variant?:
    | "default"
    | "inverted"
    | "primaryShadowed"
    | "secondary"
    | "tertiary"
    | "bordered"
    | "link"
    | "learn-more"
    | "disabled";
  size?: "small" | "medium" | "large";
  fontWeight?: "normal" | "bold";
  fontSize?: "small" | "medium" | "large";
  iconIndent?: "small" | "medium";
  target?: string;
  iconPosition?: "left" | "right";
}

function isTextLink(value: unknown): value is TextLinkSchema {
  return isSchemaObject(value, "textLink");
}

export const Button = (props: ButtonProps & React.ComponentPropsWithoutRef<"button">) => {
  const {
    iconPosition = "left",
    children,
    className,
    to,
    iconUrl,
    variant,
    size,
    fontSize,
    fontWeight,
    iconIndent,
    ...rest
  } = props;
  let text = children;

  if (isTextLink(props.to)) {
    text = props.to.text;
  }

  let iconElement: ReactNode = null;
  if (typeof iconUrl !== "undefined") {
    const iconClass = clsx(
      iconStyle,
      iconRecipe({
        iconIndent,
        position: iconPosition === "right" ? "right" : "left",
      }),
    );
    iconElement = (
      <i className={iconClass} aria-hidden>
        <Svg src={iconUrl} width={24} height={24} />
      </i>
    );
  }
  const typographyElement = (
    <Typography className={typographyStyle({ font: fontSize, fontWeight: fontWeight })}>{text}</Typography>
  );

  const content =
    iconPosition === "left" ? (
      <>
        {iconElement}
        {typographyElement}
      </>
    ) : (
      <>
        {typographyElement}
        {iconElement}
      </>
    );

  const button = (
    <button className={buttonStyle({ variant, size })} aria-label={text} {...rest}>
      {content}
    </button>
  );

  return to !== undefined ? (
    <Link className={className} to={to} target={rest.target} {...rest}>
      {button}
    </Link>
  ) : (
    <span className={className}>{button}</span>
  );
};
