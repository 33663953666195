export type EventTypes = "launchDemo" | "playDemoVideo" | "clickOnSignupLink" | "clickButton" | "playVideo";

type Callback<T> = (type: T, params: Record<string, unknown>) => void;

class AnalyticsEvent<T extends EventTypes> extends Event {
  constructor(public readonly id: T, public readonly params: Record<string, unknown>) {
    super(id);
  }
}

class BroadcastEvent<T extends EventTypes> extends Event {
  constructor(public event: AnalyticsEvent<T>) {
    super("broadcast");
  }
}

const broadcastDispatcher = new EventTarget();

export function onAnyAnalyticsEvent(callback: Callback<EventTypes>): void {
  const handler = (event: BroadcastEvent<EventTypes>) => {
    callback(event.event.id, event.event.params);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  broadcastDispatcher.addEventListener("broadcast", handler as any);
}

export function trackEvent<T extends EventTypes>(eventType: T, props?: Record<string, unknown>) {
  const event = new AnalyticsEvent(eventType, props || {});
  broadcastDispatcher.dispatchEvent(new BroadcastEvent(event));
}
