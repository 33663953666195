import { SiteLinkSchema, TextLinkSchema } from "@/schema";
import React from "react";

import { Typography } from "@/uikit/Typography";
import { Link, AppLinkProps } from "@/uikit/Link";

import { rootStyle, textStyle, iconStyle } from "./themeLink.css";

import ArrowRight from "@/svg/icons/arrow-right.svg";

type ThemeLinkProps = {
  children: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
  to: SiteLinkSchema | TextLinkSchema;
};

export const ThemeLink = ({ className, children, to, ...rest }: ThemeLinkProps & AppLinkProps) => (
  <Link className={`${rootStyle} ${className}`} to={to} {...rest}>
    <Typography className={textStyle}>{children}</Typography>
    <ArrowRight className={iconStyle} />
  </Link>
);
