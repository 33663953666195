import { PageMetaSchema } from "@/schema";
import Head from "next/head";
import * as React from "react";

export const WebsiteMeta = (props: { meta: PageMetaSchema; slug: string }) => {
  const { meta, slug } = props;
  const image = meta.seo?.cardImage;

  let cardImageURL: string | null = null;

  if (image && "src" in image) {
    cardImageURL = `https://www.appspector.com/_next/image?url=${encodeURIComponent(image.src as string)}&w=1200&q=75`;
  }
  // const useLocation

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="icon" type="image/x-icon" href="/assets/favicon.ico" />
      <meta charSet="utf-8" />

      <title>{meta.title}</title>

      <meta name="description" content={meta.seo?.description} />
      <link rel="canonical" href={`https://www.appspector.com${slug}`} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.seo?.description} />
      <meta property="og:site_name" content="AppSpector" />
      <meta property="og:url" content={`https://www.appspector.com${slug}`} />
      <meta property="og:type" content="website" />
      {cardImageURL && <meta property="og:image" content={cardImageURL} />}

      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.seo?.description} />
      <meta name="twitter:domain" content="www.appspector.com" />
      <meta name="twitter:url" content={`https://www.appspector.com${slug}`} />
      {cardImageURL && <meta name="twitter:card" content={cardImageURL} />}
      {cardImageURL && <meta name="twitter:image" content={cardImageURL} />}
    </Head>
  );
};
