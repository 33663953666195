"use client";
import { isNavItem, isTextLink } from "@/core/utils";
import { FlexContainer } from "@/uikit/container";
import React, { useRef } from "react";

import { Typography } from "@/uikit/Typography";
import { Button, Link } from "@/blocks/tracking";

import AppSpectorLogo from "@/svg/icons/appspector.svg";
import CloseIcon from "@/svg/icons/close.svg";

import { HeaderSchema } from "src/schema";

import {
  rootStyle,
  containerStyle,
  headStyle,
  logoContainerStyle,
  logoStyle,
  appNameStyle,
  menuStyle,
  linkTypographyStyle,
  closeStyle,
  buttonsStyle,
  buttonStyle,
  afterTextStyle,
  headerLinkStyle,
  linkWrapperStyle,
} from "./mobileMenu.css";
import { NavigationMenu } from "../NavigationMenu";

const MobileMenu = (data: HeaderSchema) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const closeMobileMenu = () => {
    if (rootRef && rootRef.current) {
      rootRef.current.removeAttribute("data-mobile-menu-active");
    }
  };

  return (
    <div className={rootStyle} id={"MobileMenu"} ref={rootRef} data-mobile-menu={true}>
      <FlexContainer className={containerStyle}>
        <FlexContainer justifyContent="between" className={headStyle}>
          <Link className={logoContainerStyle} to={data.homeLink}>
            <AppSpectorLogo className={logoStyle} />
            <Typography className={appNameStyle}>AppSpector &nbsp; 🇺🇦</Typography>
          </Link>
          <button className={closeStyle} aria-label="close mobile menu" onClick={closeMobileMenu}>
            <CloseIcon />
          </button>
        </FlexContainer>
        <nav className={menuStyle}>
          {data.navMenuItems.map((item, index) => {
            if (isTextLink(item)) {
              return (
                <div onClick={closeMobileMenu} className={linkWrapperStyle} key={index}>
                  <Link className={headerLinkStyle} to={item.link} eventName={item.trackingEvent}>
                    <Typography className={linkTypographyStyle}>{item.text}</Typography>
                  </Link>
                </div>
              );
            }

            if (isNavItem(item)) {
              return (
                <div className={linkWrapperStyle} key={index}>
                  <div className={headerLinkStyle}>
                    <NavigationMenu {...item} />
                  </div>
                </div>
              );
            }

            return null;
          })}
        </nav>
        <div className={buttonsStyle}>
          {data.rightSideLinks?.map((item) => (
            <Button className={buttonStyle} key={item.text} to={item.link} variant="bordered" eventName={item.trackingEvent}>
              {item.text}
            </Button>
          ))}
          {data.actionButtons.map((item) => (
            <Button className={buttonStyle} key={item.text} to={item.link} variant={item.variant} eventName={item.trackingEvent}>
              {item.text}
            </Button>
          ))}
          <Typography className={afterTextStyle}>{data.afterText}</Typography>
        </div>
      </FlexContainer>
    </div>
  );
};

export default MobileMenu;
