import React, { useEffect, useRef, useState } from "react";

import { NavItemSchema } from "@/schema";

import { Typography } from "@/uikit/Typography";
import ArrowDown from "@/svg/icons/chevron_down.svg";

import {
  NavigationMenuWrapperStyle,
  navMenuLinkWrapperStyle,
  navigationMenuLinkStyle,
  menuItemStyle,
  menuWrapperStyle,
  titleStyle,
  subtitleStyle,
  iconWrapperStyle,
  iconStyle,
  arrowIconRecipe,
} from "./navigationMenu.css";
import { FlexContainer } from "@/uikit/container";

import AppleIcon from "@/svg/icons/apple_header.svg";
import AndroidIcon from "@/svg/icons/android_header.svg";
import AllOutIcon from "@/svg/icons/all_out_header.svg";
import AmpStoriesIcon from "@/svg/icons/amp_stories_header.svg";
import DocsIcon from "@/svg/icons/docs_header.svg";
import ArmIcon from "@/svg/icons/arming_countdown_header.svg";

const iconsMapping = {
  ios: AppleIcon,
  android: AndroidIcon,
  allOut: AllOutIcon,
  ampStories: AmpStoriesIcon,
  docs: DocsIcon,
  arm: ArmIcon,
};

export const NavigationMenu = (props: NavItemSchema) => {
  const { text, menuItems } = props;

  const [open, setOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClick = ({ target }: MouseEvent) => !menuRef.current?.contains(target as Node) && open && setOpen(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [open, setOpen]);

  return (
    <div className={NavigationMenuWrapperStyle} ref={menuRef}>
      <div onClick={() => setOpen(!open)}>
        <FlexContainer className={navMenuLinkWrapperStyle} alignItems="center" gap="none">
          <Typography settings={{ tag: "p" }} className={navigationMenuLinkStyle({ active: open })}>
            {text}
          </Typography>
          <ArrowDown className={arrowIconRecipe({ open })} />
        </FlexContainer>
      </div>
      <div className={menuWrapperStyle({ open })}>
        {menuItems.map((item, index) => {
          const Icon = iconsMapping[item.type];
          const handleClick = (link) => {
            window.location.href = link;
          };

          if (!Icon) {
            return null;
          }

          return (
            <FlexContainer onClick={() => handleClick(item.link)} key={index}>
              <FlexContainer alignItems="start" key={index} className={menuItemStyle}>
                <FlexContainer justifyContent="center" alignItems="start" className={iconWrapperStyle}>
                  <Icon className={iconStyle} />
                </FlexContainer>
                <div>
                  <Typography settings={{ tag: "p" }} className={titleStyle}>
                    {item.title}
                  </Typography>
                  <Typography settings={{ tag: "p" }} className={subtitleStyle}>
                    {item.subtitle}
                  </Typography>
                </div>
              </FlexContainer>
            </FlexContainer>
          );
        })}
      </div>
    </div>
  );
};
