import { resolveRef } from "@/core/sanityAPI/client-resolvers";
import { ResolvedSiteLinkSchema } from "@/core/sanityAPI/resolvers/site-link";
import { isSchemaObject } from "@/core/utils";
import { SiteLinkSchema, TextLinkSchema } from "@/schema";
import { clsx } from "@/uikit/utils";
import React, { MouseEventHandler } from "react";
import NextLink from "next/link";

import { linkStyle } from "./link.css";

interface ExternalLink {
  to: SiteLinkSchema | TextLinkSchema;
}

interface BaseLinkProps extends React.PropsWithChildren {
  className?: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

export type AppLinkProps = BaseLinkProps & ExternalLink;

function isSiteLinkSchema(link: unknown): link is SiteLinkSchema {
  return isSchemaObject(link, "siteLink");
}

function isTextLinkSchema(link: unknown): link is TextLinkSchema {
  return isSchemaObject(link, "textLink");
}

export const Link = React.memo((props: AppLinkProps) => {
  const combinedClassName = clsx(linkStyle, props.className);

  let href;
  let children = props.children;

  let link: ResolvedSiteLinkSchema | undefined = undefined;

  if (isSiteLinkSchema(props.to)) {
    link = resolveRef(props.to);
  }

  if (isTextLinkSchema(props.to)) {
    link = resolveRef(props.to.link);
    children = children || props.to.text;
  }

  if (link && link.linkType == "internal") {
    const internalLink = resolveRef(link.internalLink);
    href = internalLink?.slug?.current;
    if (href && !href.startsWith("/")) {
      href = `/${href}`;
    }
  } else {
    href = link?.href;
  }

  const target = link?.blank ? "_blank" : undefined;

  // if (!href) {
  //   console.error("Link href is undefined", props);
  // }

  href = href || "/404";

  return (
    <NextLink className={combinedClassName} href={href} target={target}>
      {children}
    </NextLink>
  );
});

Link.displayName = "Link";
