import React from "react";

type SvgInlinePropsSrc = {
  src: string;
  className?: string;
  width?: number;
  height?: number;
};

type SvgInlinePropsSvg = {
  svg: string;
  className?: string;
  width?: number;
  height?: number;
};

export type SvgInlineProps = SvgInlinePropsSrc | SvgInlinePropsSvg;

type SVGInlineState = {
  svg: string;
  loading: boolean;
};

export class SvgInline extends React.Component<SvgInlineProps, SVGInlineState> {
  override state = {
    svg: "",
    loading: false,
  };

  override componentDidMount() {
    if ("svg" in this.props) {
      this.setState({ svg: this.props.svg });
      return;
    }
    fetch(this.props.src)
      .then((res) => res.text())
      .then((text) => {
        this.setState({ svg: text });
      })
      .catch((err) => console.log("Failed fetching: ", err));
  }

  override render() {
    const { loading, svg } = this.state;
    const { className, width, height } = this.props;

    if (loading) {
      return <div className="spinner" />;
    } else if (!svg) {
      return <div className="error" />;
    }
    return <div className={className} style={{ width, height }} dangerouslySetInnerHTML={{ __html: this.state.svg }} />;
  }
}
