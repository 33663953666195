"use client";

import { isNavItem, isTextLink, isSameLinkOrParentSlug } from "@/core/utils";
import { FlexContainer } from "@/uikit/container";
import { usePathname } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@/uikit/Typography";
import { Link } from "@/blocks/tracking";

import { HeaderSchema } from "src/schema";
import AppSpectorLogo from "@/svg/icons/appspector.svg";
import MenuIcon from "@/svg/icons/menu.svg";

import {
  rootStyle,
  styledContainer,
  styledLogoContainer,
  styledLogo,
  styledAppName,
  styledMenu,
  linkStyle,
  activeLinkStyle,
  linkTypographyStyle,
  rightSideContainerWrapperStyle,
  dividerStyle,
  hamburgerStyle,
  navMenuWrapperStyle,
  actionsWrapper,
} from "./header.css";
import { NavigationMenu } from "../NavigationMenu";
import { buttonFromSchema } from "@/blocks/schema-mappers";

interface HeaderProps {
  data: HeaderSchema;
}

export const Header = React.memo(({ data }: HeaderProps) => {
  const mobileMenuRef = useRef<Element>();
  const headerRef = useRef<HTMLHeadElement>(null);
  const [bgColor, setBgColor] = useState<"transparent" | "white">("transparent");
  const pathname = usePathname();

  const openMobileMenu = () => {
    if (mobileMenuRef && mobileMenuRef.current) {
      mobileMenuRef.current.setAttribute("data-mobile-menu-active", "true");
    }
  };

  useEffect(() => {
    mobileMenuRef.current = document.querySelector("[data-mobile-menu]") || undefined;

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBgColor("white");
      } else {
        const headerHeight = headerRef.current?.scrollHeight || 0;
        if (window.scrollY > headerHeight / 4) {
          setBgColor("white");
        } else {
          setBgColor("transparent");
        }
      }
    };
    handleResize();
    window.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [pathname]);

  return (
    <>
      <header className={rootStyle({ backgroundColor: bgColor })} ref={headerRef}>
        <FlexContainer alignItems={"center"} justifyContent={"between"} className={styledContainer}>
          <Link to={data.homeLink} className={styledLogoContainer}>
            <AppSpectorLogo className={styledLogo} />
            <Typography className={styledAppName}>AppSpector &nbsp; 🇺🇦</Typography>
          </Link>
          <nav className={styledMenu}>
            {data.navMenuItems?.map((item, index) => {
              if (isTextLink(item)) {
                const href = item.link.href || "/";
                const active = isSameLinkOrParentSlug(pathname, href);
                return (
                  <Link to={item.link} key={index} className={`${linkStyle} ${active ? activeLinkStyle : ""}`} eventName={item.trackingEvent}>
                    <Typography className={linkTypographyStyle({ active })}>{item.text}</Typography>
                  </Link>
                );
              }

              if (isNavItem(item)) {
                return (
                  <div className={navMenuWrapperStyle} key={index}>
                    <NavigationMenu {...item} />
                  </div>
                );
              }

              return null;
            })}
            <button className={hamburgerStyle()} aria-label="open mobile menu" onClick={openMobileMenu}>
              <MenuIcon />
            </button>
          </nav>
          <FlexContainer className={rightSideContainerWrapperStyle}>
            <div className={dividerStyle} />
            <FlexContainer className={actionsWrapper}>
              {data.actionButtons.map((btn) => buttonFromSchema(btn))}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </header>
    </>
  );
});

Header.displayName = "Header";
