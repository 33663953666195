"use client";

import { CookiesTypeSchema } from "@/schema";
import { Analytics } from "@vercel/analytics/react";
import dynamic from "next/dynamic";
import React from "react";

export * from "./tracker";

const AnalyticsRoot = dynamic(() => import("./root"), { ssr: false });

interface AnalyticsProviderProps {
  permission: CookiePermissions | undefined;
}

export const AnalyticsProvider = React.memo((props: AnalyticsProviderProps) => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  if (typeof window !== "undefined") {
    setTimeout(() => {
      setIsLoaded(true);
    }, 300);
  }

  if (process.env.NEXT_PUBLIC_DISABLE_TRACKING === "true") {
    return null;
  }

  if (isLoaded) {
    return (
      <>
        <Analytics />
        <AnalyticsRoot permission={props.permission} />
      </>
    );
  } else {
    return null;
  }
});

AnalyticsProvider.displayName = "AnalyticsProvider";

export type CookieType = CookiesTypeSchema["type"];
export type CookiePermissions = Record<CookieType, boolean>;
