/* eslint-disable react/display-name */

import React, { createContext, useState } from "react";

interface TypographyContextProps {
  h1Tag: ReturnType<typeof useState>;
}

export const TypographyContext = createContext<TypographyContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  h1Tag: [false, () => {}],
});

import { typographyStyle } from "./typography.css";

const getTag = (settings: Settings) => {
  const mode = settings?.mode;

  switch (settings?.tag) {
    case "h1":
      return ({ children, className }) => (
        <h1 className={`${typographyStyle({ mode: mode, tag: "h1" })} ${className}`}>{children}</h1>
      );
    case "h2":
      return ({ children, className }) => (
        <h2 className={`${typographyStyle({ mode: mode, tag: "h2" })} ${className}`}>{children}</h2>
      );
    case "h3":
      return ({ children, className }) => (
        <h3 className={`${typographyStyle({ mode: mode, tag: "h3" })} ${className}`}>{children}</h3>
      );
    case "h4":
      return ({ children, className }) => (
        <h4 className={`${typographyStyle({ mode: mode, tag: "h4" })} ${className}`}>{children}</h4>
      );
    case "h5":
      return ({ children, className }) => (
        <h5 className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</h5>
      );
    case "h6":
      return ({ children, className }) => (
        <h6 className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</h6>
      );
    case "p":
      return ({ children, className }) => (
        <p className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</p>
      );
    default:
      return ({ children, className }) => (
        <span className={`${typographyStyle({ mode: mode, tag: "text" })} ${className}`}>{children}</span>
      );
  }
};

type Settings = {
  mode?: "light" | "dark";
  tag?: string;
};

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  settings?: Settings;
}

export const Typography = ({ children, className, settings, ...rest }: TypographyProps) => {
  const StyledTag = getTag(settings || {});

  return (
    <StyledTag className={className} {...rest}>
      {children}
    </StyledTag>
  );
};

Typography.title = (props: React.HTMLAttributes<HTMLElement>) => {
  // const [hasH1Tag, setHasH1Tag] = useContext(TypographyContext).h1Tag;
  // console.warn("hasH1Tag", hasH1Tag);
  // if (hasH1Tag) {
  //   return <Typography settings={{ tag: "h2" }} {...props} />;
  // }
  // setHasH1Tag(true);
  return <Typography settings={{ tag: "h1" }} {...props} />;
};

// h3
Typography.subtitle = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h3" }} {...props} />;
};

Typography.h4 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h4" }} {...props} />;
};

Typography.h5 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h5" }} {...props} />;
};

Typography.h6 = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "h6" }} {...props} />;
};

Typography.p = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "p" }} {...props} />;
};

Typography.span = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Typography settings={{ tag: "span" }} {...props} />;
};
