import { getResolvedImage } from "@/core/sanityAPI/types";
import { ButtonSchema } from "@/schema";
import { Button, Link, ThemeLink } from "@/blocks/tracking";
import { FlexContainer } from "@/uikit/container";
import React from "react";

export function renderActions(actions: ButtonSchema[]) {
  return (
    <FlexContainer gap="small" wrap="wrapWithChildGrow">
      {actions?.map(buttonFromSchema)}
    </FlexContainer>
  );
}
export function buttonFromSchema(button: ButtonSchema) {
  if (button.variant === "learn-more") {
    const isExternal = button.link.href.startsWith("http");

    const target = isExternal ? "_blank" : undefined;

    return (
      <ThemeLink key={button.link?.href} to={button.link} target={target} eventName={button.trackingEvent}>
        {button.text}
      </ThemeLink>
    );
  }

  if (button.variant === "link") {
    return (
      <Link key={button.link?.href} to={button.link} eventName={button.trackingEvent}>
        {button.text}
      </Link>
    );
  }

  const iconUrl = button.icon && getResolvedImage(button.icon)?.src;

  const target = button.link?.blank ? "_blank" : undefined;

  return (
    <Button
      key={button.link?.href}
      size={button.size}
      variant={button.variant}
      fontSize={button.fontSize}
      fontWeight={button.fontWeight}
      iconIndent={button.iconIndent}
      iconPosition={button.iconPosition}
      target={target}
      to={button.link}
      eventName={button.trackingEvent}
      iconUrl={iconUrl}
    >
      {button.text}
    </Button>
  );
}
