import React from "react";
import { Button as OriginalButton } from "@/uikit/Button";
import { Link as OriginalLink } from "@/uikit/Link";
import { ThemeLink as OriginalThemeLink } from "@/uikit/ThemeLink";

import { trackEvent } from "@/core/analytics";
import posthog from "posthog-js";

/**
 * Decorate onClick event for tracking purposes
 * 
 * @param params - event parameters
 * @returns onClick event handler
 */
const decorateOnClick = (params) => {
  return (event) => {
    trackEvent("clickButton", {
      label: params.eventName || "",
      href: params.to?.href || "",
    });
    if (isLinkToApplication(params.to?.href) && openApplicationLink(params.to.href)) {
      event.preventDefault();
    } else if (params.onClick) {
      return params.onClick();
    }
  };
};

/**
 * Check if link is to the application
 * 
 * @param href - link to application
 * @returns true if link is to the application, false otherwise
 */
const isLinkToApplication = (href: string | null) => {
  return href?.includes("app.appspector.com") || false;
};


/**
 * Open application link in new tab with posthog distinct_id in case
 * if user doesn't accept cookies consent and posthog persistence is disabled.
 * 
 * @param href - link to application
 * @returns true if link was opened in new tab, false otherwise
 */
const openApplicationLink = (href: string) => {
  if (posthog.config.disable_persistence) {
    const url = new URL(href);
    url.searchParams.set("ph", posthog.get_distinct_id());
    window.open(url.toString(), "_blank", "noopener,noreferrer");
    return true;
  }
  return false;
};

type TrackedComponentProps<T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>> =
  React.ComponentProps<T> & {
    eventName?: string | undefined;
  };

export const Button = (params: TrackedComponentProps<typeof OriginalButton>) => {
  return <OriginalButton {...params} onClick={decorateOnClick(params)} />;
};
export const Link = (params: TrackedComponentProps<typeof OriginalLink>) => {
  return <OriginalLink {...params} onClick={decorateOnClick(params)} />;
};
export const ThemeLink = (params: TrackedComponentProps<typeof OriginalThemeLink>) => {
  return <OriginalThemeLink {...params} onClick={decorateOnClick(params)} />;
};
