import type { CookiePermissions } from "@/core/analytics";
import { useState } from "react";

const consentKey = "appspector-consent-v2";

export function useCookiesConcentState() {
  const [existingConsent, setExistingConsent] = useState<CookiePermissions>(() => {
    if (typeof window !== "undefined") {
      const data = localStorage.getItem(consentKey);
      if (data) {
        return JSON.parse(data);
      }
    }
    return undefined;
  });

  return {
    existingConsent,
    setExistingConsent: (settings: CookiePermissions) => {
      if (typeof window !== "undefined") {
        localStorage.setItem(consentKey, JSON.stringify(settings));
      }

      setExistingConsent(settings);
    },
  };
}
